import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, variant } from 'styled-system';
import Hamburger from '../Hamburger';
import Link from '../Link';
import defaultTheme from '../../utils/themes';

const navLinksVariants = variant({
  scale: 'navLinksVariants',
  prop: 'variant',
});

export const NavigationLinks = styled.ul`
  position: relative;
  margin: 0;
  width: fit-content !important;

  ${navLinksVariants}
`;

export const NavigationListItem = styled.li`
  list-style: none;
  margin-left: 16px;
  ${space}
`;

NavigationListItem.defaultProps = {
  marginBottom: {
    xs: '45px',
    lg: '0px',
  },
};

export const NavigationLink = ({ activeStyle, ...otherProps }) => {
  const [isActive, setIsActive] = useState(false);

  const getProps = useCallback(
    ({ isCurrent, isPartiallyCurrent, href, location }) => {
      const pathname = location.pathname;
      setIsActive(isCurrent || (isPartiallyCurrent && `${href}/` === pathname));
    },
    []
  );

  return (
    <Link
      getProps={getProps}
      style={isActive ? activeStyle : undefined}
      {...otherProps}
    />
  );
};

NavigationLink.propTypes = {
  activeStyle: PropTypes.object,
};

NavigationLink.defaultProps = {
  activeStyle: {
    color: 'white',
    borderBottom: '2px solid #43c094',
  },
  color: {
    xs: 'white',
    lg: 'rgba(255, 255, 255, 0.644)',
  },
  hoverColor: 'white',
  letterSpacing: '.025em',
  textDecoration: 'none',
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: '600',
};

const Navigator = ({ LinkComponent: Link, links, ...otherProps }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = useCallback(
    () => setIsActive((prevIsActive) => !prevIsActive),
    []
  );

  return (
    <nav {...otherProps}>
      <Hamburger
        display={useMemo(
          () => ({
            xs: 'inline-block',
            lg: 'none',
          }),
          []
        )}
        onClick={handleClick}
      />

      <NavigationLinks
        variant={useMemo(
          () => ({
            xs: isActive ? 'mobile' : 'hidden',
            lg: 'desktop',
          }),
          [isActive]
        )}
      >
        {links.map(({ content, ...otherLinkProps }, i) => (
          <NavigationListItem key={i}>
            <Link {...otherLinkProps}>{content}</Link>
          </NavigationListItem>
        ))}
      </NavigationLinks>
    </nav>
  );
};

Navigator.propTypes = {
  LinkComponent: PropTypes.elementType.isRequired,
  links: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

Navigator.defaultProps = {
  LinkComponent: NavigationLink,
  theme: defaultTheme,
};

export default Navigator;
