import { color, compose, space, typography } from 'styled-system';
import styled, { css } from 'styled-components';

import Button from '../Button';
import Container from '../Container';
import PropTypes from 'prop-types';
import React from 'react';
import propTypes from '@styled-system/prop-types';
import { navigate } from 'gatsby';

const style = compose(color, space, typography);

export const HeaderContainer = styled.header(style);

const Inner = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	@media (max-width: 522px) {
		padding: 0;
	}
`;

const ContainerStyle = css`
	padding-top: 12px;
	margin-bottom: 12px;
	@media (max-width: 522px) {
		padding: 2px 0 0 0;
	}
`;

const ButtonStyle = css`
	margin-left: 8px;
	@media (max-width: 522px) {
		padding: 0;
		font-size: 12px;
		line-height: 12px;
		border-radius: 0;
		width: 100%;
		margin: 0px;
		border: solid 1px #001389;
	}
`;

const Header = ({ onLoginButtonClick, ...otherProps }) => (
	<HeaderContainer {...otherProps}>
		<Container css={ContainerStyle}>
			<Inner>
				<Button
					css={ButtonStyle}
					onClick={() => navigate('/services')}
					fontSize='0.7rem'
					letterSpacing='0.4px'
					height='30px'
					py='0px'
				>
					Self-Service
				</Button>
				<Button
					css={ButtonStyle}
					onClick={() => navigate('/pay-now')}
					target='__blank'
					fontSize='0.7rem'
					letterSpacing='0.4px'
					height='30px'
					py='0px'
				>
					Pay Now
				</Button>

				<Button
					css={ButtonStyle}
					onClick={onLoginButtonClick}
					fontSize='0.7rem'
					letterSpacing='0.4px'
					height='30px'
					py='0px'
				>
					Client Login
				</Button>
			</Inner>
		</Container>
	</HeaderContainer>
);

Header.propTypes = {
	...propTypes.color,
	theme: PropTypes.object,
};

const MemoizedHeader = React.memo(Header);

MemoizedHeader.defaultProps = {
	textAlign: 'right',
};

export default MemoizedHeader;
