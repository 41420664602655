import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { display } from 'styled-system';

export const HamburgerContainer = styled.button`

    padding: 15px 15px;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
    cursor: pointer;

    ${display}
`;

export const HamburgerBox = styled.span`

    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
`;

export const HamburgerInner = styled.span`

    display: block;
    margin-top: -2px;

    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &, :before, :after {

        width: 38px;
        height: 3px;
        background-color: white;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    :before, :after {

        content: "";
        display: block;
    }

    :before {

        top: -10px;
        
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    
    :after {

        bottom: -10px;

        top: -20px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            opacity 0.1s linear;
    }

    ${({ active }) => {

        if (!active) return '';

        return css`

            transform: translate3d(0, -10px, 0) rotate(-45deg);
            transition-delay: 0.22s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            :after {

                top: 0;
                opacity: 0;
                transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    opacity 0.1s 0.22s linear;
            }

            :before {

                top: 0;
                transform: rotate(-90deg);
                transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            &, :before, :after {
                background-color: white;
            }              
        `;
    }}
`;

const Hamburger = ({
    onClick,
    theme,
    ...otherProps
}) => {

    const [isActive, setIsActive] = useState(false);

    const handleClick = useCallback(() => {

        const newIsActive = !isActive;

        setIsActive(newIsActive);
        if (onClick) onClick(newIsActive);

    }, [isActive, onClick]);

    return (
        <HamburgerContainer
            onClick={handleClick}
            theme={theme}
            {...otherProps}
        >
            <HamburgerBox>
                <HamburgerInner
                    active={isActive}
                />
            </HamburgerBox>
        </HamburgerContainer>
    );
};

Hamburger.propTypes = {
    theme: PropTypes.object,
    onClick: PropTypes.func
};

Hamburger.defaultProps = {
    display: 'inline-block'
};

export default Hamburger;