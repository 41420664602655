import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from '../Typography';
import Link from '../Link';
import styled from 'styled-components';

const PaginationContainer = styled.div`
    margin-top: 40px;
`;

const Pagination = ({
    total,
    current,
    LinkComponent: Link,
}) => {

    if (total < 2) return null;

    const isFirst = current === 1;
    const isLast = current === total;

    return (
        <PaginationContainer>
            
            <Paragraph
                color="#9f9f9f"
                fontSize="0.7rem"
                fontWeight="700"
                marginBottom="0.5rem"
                textTransform="uppercase"
            >
                Page {current} of {total}
            </Paragraph>

            {!isFirst && (
                <Link
                    fontSize="0.9rem"
                    href={(current - 1) === 1 ? '/news' : `/news/${current - 1}`}
                    marginRight="1rem"
                    textDecoration="none"
                >
                    « Previous
                </Link>
            )}

            {!isLast && (
                <Link
                    fontSize="0.9rem"
                    href={`/news/${current + 1}`}
                    textDecoration="none"
                >
                    Next »
                </Link>
            )}

        </PaginationContainer>
    );
};

Pagination.propTypes = {
    current: PropTypes.number.isRequired,
    LinkComponent: PropTypes.elementType,
    total: PropTypes.number.isRequired
};

Pagination.defaultProps = {
    LinkComponent: Link
};

export default Pagination;
