import {
    borderRadius,
    boxShadow,
    color,
    compose,
    height,
    maxWidth,
    minHeight,
    position,
    space,
    width,
    flexbox,
} from 'styled-system';

import PropTypes from 'prop-types';
import defaultTheme from '../../utils/themes';
import propTypes from '@styled-system/prop-types';
import styled from 'styled-components';

const style = compose(
    borderRadius,
    boxShadow,
    color,
    height,
    maxWidth,
    minHeight,
    position,
    space,
    width,
    flexbox
);

const Card = styled.div`
    h2 {
        font-size: 1.3rem;
    }

    p {
        font-size: 0.9rem;
    }

    ${style}
`;

Card.propTypes = {
    ...propTypes.borderRadius,
    ...propTypes.boxShadow,
    ...propTypes.color,
    ...propTypes.height,
    ...propTypes.maxWidth,
    ...propTypes.minHeight,
    ...propTypes.position,
    ...propTypes.space,
    ...propTypes.width,
    ...propTypes.flexbox,

    theme: PropTypes.object,
};

Card.defaultProps = {
    backgroundColor: 'white',
    borderRadius: 'medium',
    boxShadow: 'elevated',
    position: 'relative',
    theme: defaultTheme,
};

export default Card;
