import { Heading1, Heading2, Paragraph } from '../components/Typography';
import styled, { css } from 'styled-components';

import Card from '../components/Card';
import GatsbyImage from 'gatsby-image';
import Grid from '../components/Grid';
import HeroSection from '../components/HeroSection';
import Link from '../components/Link';
import Media from '../components/Media';
import Pagination from '../components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import SEO from '../components/seo';
import Section from '../components/Section';
import { graphql } from 'gatsby';

const UnstyledNewsImage = ({ node, ...otherProps }) => {
    if (node.childImageSharp && node.childImageSharp.fluid) {
        return (
            <GatsbyImage fluid={node.childImageSharp.fluid} {...otherProps} />
        );
    }

    if (node.childImageSharp && node.childImageSharp.fixed) {
        return (
            <GatsbyImage fixed={node.childImageSharp.fixed} {...otherProps} />
        );
    }

    return <Media src={node.publicURL} {...otherProps} />;
};

UnstyledNewsImage.propTypes = {
    node: PropTypes.object.isRequired,
};

const NewsImage = styled(UnstyledNewsImage)`
    margin-bottom: 16px;
    border: 1px solid #e6e6e6;
    height: auto;
`;

const NewsCard = ({ date, excerpt, picture, title, href }) => {
    return (
        <Link href={href} textDecoration='none'>
            <Card
                paddingBottom='15px'
                paddingLeft='15px'
                paddingRight='15px'
                paddingTop='15px'
                height='100%'
                css={`
                    h2 {
                        font-size: 0.9rem;
                    }
                    p {
                        font-size: 0.8rem;
                    }
                `}
            >
                <NewsImage node={picture} />
                <Heading2
                    lineHeight='1.35'
                    marginBottom='0.75rem'
                    fontSize='0.95rem'
                >
                    {title}
                </Heading2>
                <Paragraph
                    fontSize='0.8rem'
                    fontWeight='500'
                    lineHeight='0.65'
                    marginBottom='1rem'
                    color='#a1a1a1'
                >
                    {date}
                </Paragraph>
                <Paragraph
                    color='#595959'
                    fontWeight='400'
                    lineHeight='1.2rem'
                    marginBottom='0.8rem'
                >
                    {excerpt}
                </Paragraph>
            </Card>
        </Link>
    );
};

NewsCard.propTypes = {
    date: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    picture: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
};

const NewsListLayout = ({ pageResources }) => {
    //if (!pageResources) return null;
    // const {
    //     json: {
    //         data: {
    //             allMdx: { edges }
    //         },
    //         pageContext: { currentPage, numPages }
    //     }
    // } = pageResources;

    const news = pageResources
        ? pageResources.json.data.allMdx.edges.map(
              ({
                  node: {
                      excerpt,
                      id,
                      frontmatter: { date, picture, title },
                      fields: { slug },
                  },
              }) => {
                  return (
                      <NewsCard
                          key={id}
                          picture={picture}
                          date={date}
                          title={title}
                          excerpt={excerpt}
                          href={slug}
                      />
                  );
              }
          )
        : 'No Data found';

    return (
        <>
            <HeroSection paddingTop='8px'>
                <SEO
                    title='News'
                    description='The latest Jacobs Enforcement news'
                />
                <Heading1 marginTop='120px'>Latest News</Heading1>
            </HeroSection>

            <Section
                backgroundColor='grey'
                paddingTop='78px'
                paddingBottom='164px'
            >
                <Grid
                    ieCardLayout
                    ieAlign='space-between'
                    ieMaxCard={380}
                    display='grid'
                    gridTemplateColumns='repeat(auto-fill, minmax(281px, 1fr))'
                    gridGap='40px'
                >
                    {news}
                </Grid>

                {pageResources &&
                    pageResources.json.pageContext.numPages > 1 && (
                        <Pagination
                            current={
                                pageResources
                                    ? pageResources.json.pageContext.currentPage
                                    : 0
                            }
                            LinkComponent={Link}
                            total={
                                pageResources
                                    ? pageResources.json.pageContext.numPages
                                    : 0
                            }
                        />
                    )}
            </Section>
        </>
    );
};

NewsListLayout.propTypes = {
    pageResources: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
    query($skip: Int!, $limit: Int!) {
        allMdx(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { fields: { type: { eq: "NEWS" } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 150)
                    frontmatter {
                        date(formatString: "DD MMM YYYY")
                        title
                        picture {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 980) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;

export default NewsListLayout;
