import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { pathOr } from 'ramda';
import Section from '../Section';
import Flexbox from '../Flexbox';
import Link from '../Link';
import Media from '../Media';
import Navigator from '../Navigator';
import jacobsLogo from '../../images/jacobs_logo.svg';
import Header from '../Header/Header';

export const HeroSectionContainer = React.memo(styled(Section)`
	> div {
		max-width: 100% !important;
		padding: 0;
	}
	p,
	h1 {
		color: white;
	}

	h1 {
		font-size: 2.7rem;
	}

	p {
		font-size: 1.1rem;
		margin-bottom: 2rem;
	}
`);

export const HeroSectionContentWrapper = styled.div`
	max-width: 650px;
`;

const HeroSection = ({
	children,
	ContentWrapperComponent: ContentWrapper,
	...otherProps
}) => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					navigator {
						href
						content
					}
				}
			}
		}
	`);
	const navigatorLinks = pathOr(
		[],
		['site', 'siteMetadata', 'navigator'],
		data
	);
	const handleLoginButtonClick = useCallback(() => navigate('/login'), []);

	return (
		<HeroSectionContainer
			HeroSectionContainer
			background='diamond'
			{...otherProps}
		>
			<Header onLoginButtonClick={handleLoginButtonClick} />
			<Flexbox
				justifyContent='space-between'
				marginBottom={40}
				style={{
					maxWidth: '1280px',
					padding: '0 1rem',
					margin: '0 auto',
				}}
			>
				<Link href='/'>
					<Media
						marginBottom='0px'
						marginLeft='0px'
						src={jacobsLogo}
						alt='Jacobs Logo'
					/>
				</Link>

				<Navigator links={navigatorLinks} width={654} />
			</Flexbox>
			<ContentWrapper
				style={{
					maxWidth: '1280px',
					padding: '0 1rem',
					margin: '0 auto',
				}}
			>
				{children}
			</ContentWrapper>
		</HeroSectionContainer>
	);
};

HeroSection.propTypes = {
	children: PropTypes.node,
	ContentWrapperComponent: PropTypes.elementType,
	theme: PropTypes.object,
};

HeroSection.defaultProps = {
	ContentWrapperComponent: HeroSectionContentWrapper,
	paddingBottom: '70px',
	paddingTop: '8px',
};

export default HeroSection;
